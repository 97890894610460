@import '../../lib/variables';

.wrapper {
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.1rem;
    border-bottom: 1px solid #eeeeee;
    max-width: 1350px;
    margin: auto;
}

.headerLogo {
    margin-left: 4rem;
    max-height: 70%;
}

.headerToolbar {
    position: relative;
    display: flex;
    gap: 2rem;
    margin-right: 4rem;
    align-items: center;
}

.headerToolbarDropdown {
    padding: 0.55rem 1.2rem 0.55rem 1.6rem;
    border-radius: 1.8rem;
    border: solid 1px transparent;
    box-shadow: 1px 130px 1px #ffffff inset;
    background: $blue_to_green_gradient;
    text-transform: capitalize;
    font-size: 1.2rem;
}

.headerToolbarDropdownButtonIcon {
    height: 1rem;
    margin-top: 0.1rem;
    margin-left: 0.3rem;
}

.headerToolbarDropdownOption {
    box-shadow: none;
}

.headerToolbarLogoutButton {
    border: none;
    background: transparent;
    font-family: $font_family;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
}

.headerToolbarLogoutButtonIcon {
    height: 1.2rem;
    margin-left: 0.3rem;
}

.blur {
    filter: blur(0.15rem);
}
