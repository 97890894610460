@import '../../lib/variables.scss';

.wrapper {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 24rem;
    height: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    z-index: 30;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: white;
    font-size: 1.3rem;
    padding: 3rem;
    text-align: center;
    font-weight: 600;
}

.removeModalButtonContainer {
    margin-top: 1.8rem;
    display: flex;
    gap: 1.5rem;
}

.removeModalButton {
    width: 7rem;
    height: 3.5rem;
    border-radius: 2rem;
    font-size: 1.2rem;
    cursor: pointer;
}

.removeModalButtonDismiss {
    border: 1px solid transparent;
    background: $blue-to-green-gradient;
    font-size: 1.1rem;
    color: white;
    &:active {
        background: $transparent_blue_to_green_gradient;
    }
}

.removeModalButtonApprove {
    border: 1px solid $light_grey;
    background: white;
    color: $dark_grey;
    &:active {
        background: $light_grey;
    }
}
